import React from 'react';
import ApexCharts from 'react-apexcharts';
import useAction from '../../../store/actions';

/**
 *
 * @export
 * @param {Array} lines
 * @param {Array} data
 * @param {Array} colors
 * @param {Object} config
 *
 * @return {JSX}
 */
export default function ChartBarStacked({lines, data, colors, config, height}) {
	const sAction = useAction();
	const textColor = sAction.dsGet('user/darkMode') === 1 ? '#FFF' : '#222';

	let options = {
		xaxis: {
			categories: lines,
			lines: {
				show: false,
			},
			labels: {
				show: false,
				style: {
					color: textColor,
				},
			},
			axisBorder: {
				show: false,
			},
			axisTicks: {
				show: false,
			},
		},
		colors: colors,
		chart: {
			type: 'bar',
			stacked: true,
			toolbar: {show: false},
			fontFamily: 'Cabin, Roboto, sans-serif',
		},
		plotOptions: {
			bar: {
				horizontal: true,
				dataLabels: {
					total: {
						enabled: true,
						offsetX: 5,
						offsetY: 2,
						style: {
							fontSize: '1.4rem',
							color: textColor,
						},
						formatter: (val) => {
							return val.toFixed(config?.decimals || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
						},
					},
				},
			},
		},
		dataLabels: {
			enabled: true,
			style: {
				color: [textColor],
			},
			formatter: (val) => {
				return val.toFixed(config?.decimals || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
			},
		},
		stroke: {
			width: 0,
			colors: ['#ffffff'],
		},
		title: {
			text: '',
			style: {
				color: textColor,
			},
		},
		yaxis: {
			show: true,
			labels: {
				show: true,
				align: 'left',
				style: {
					colors: [textColor],
					fontSize: '1.4rem',
				},
				offsetX: -20,
				offsetY: 0,
				rotate: 0,
			},
		},
		grid: {show: false},
		fill: {
			opacity: 1,
		},
		legend: {
			position: 'top',
			horizontalAlign: 'center',
			offsetX: 0,
			floating: true,
			fontSize: '14px',
			labels: {
				colors: [textColor, textColor, textColor, textColor, textColor, textColor, textColor, textColor, textColor, textColor],
			},
			itemMargin: {
				horizontal: 5,
				vertical: 5,
			},
		},
		tooltip: {
			y: {
				formatter: (val) => {
					return val.toFixed(config?.decimals || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
				},
				style: {
					color: textColor,
				},
			},
		},
	};

	return <ApexCharts series={data} options={options} type="bar" width="100%" height={'100%'} />;
}
