import React, {useState} from 'react';
import utils from '../../../../utils';
import useAction from '../../../../store/actions';
import Popover from '../../../Common/Popover/Popover';
import SubpanelTable from './SubpanelTable';
import Tooltip from '../../../Common/Tooltip/Tooltip';

/**
 * @export
 * @param {Object} columns
 * @param {Array} data
 * @param {String} relatedModule
 * @param {String} label
 * @param {String} color
 * @param {Number | String} recordId
 * @param {String} module
 * @param {String} subpanelKey
 * @param {String} prefix
 * @param {Object} subpanels
 * @param {Array} customActions
 * @param {Number | String} variantId
 * @param {Array} disableRowActions
 * @param {Array} actions
 *
 * @return {JSX}
 */
export default function Subpanel({
	columns,
	data,
	relatedModule,
	label,
	color,
	recordId,
	module,
	subpanelKey,
	prefix,
	subpanels,
	customActions,
	variantId,
	rowActions,
	actions,
}) {
	const sAction = useAction();
	const [open, setOpen] = useState(true);
	const [addOpen, setAddOpen] = useState(false);
	const [addAnchor, setAddAnchor] = useState(null);
	const [offset, setOffset] = useState(0);
	const [customOpen, setCustomOpen] = useState(false);

	/**
	 * Add record to subpanel
	 *
	 * @param {Event} e
	 */
	const handleAdd = (e) => {
		setAddAnchor(e.currentTarget);
		setAddOpen(!addOpen);
	};

	/**
	 * Handles custom action
	 *
	 * @param {Event} e
	 */
	const handleCustomActions = (e) => {
		setAddAnchor(e.currentTarget);
		setCustomOpen(!customOpen);
	};

	/***/
	const createNew = () => {
		sAction.openPopup('DetailFormPopup', `LBL_${relatedModule.toUpperCase()}`, false, { module: relatedModule, recordId: 'newRecord' }, (detailData) => {
			const params = {
				module: module,
				toModule: relatedModule,
				records: [detailData.id],
				recordId: recordId,
			};
			if (variantId) {
				params.variantId = variantId;
			}
			utils.post('createRelation', params).then((res) => {
				const updatedData = subpanels;
				updatedData[subpanelKey].data = res.data;

				if (!variantId) {
					// standard subpanel
					sAction.initSubpanels(updatedData, prefix);
				} else {
					// variants
					sAction.dsSet(`${prefix}/data`, res.data.data);
				}
			});
		});
	};

	/***/
	const addExisting = () => {
		const disabledRecords = [];
		const hiddenRecords = [];
		data.forEach((row) => {
			disabledRecords.push(row.id.toString());
		});
		if (module === relatedModule) {
			hiddenRecords.push(recordId.toString());
		}

		const additionalData = {};
		if (module === 'user') {
			additionalData.recordId = recordId;
		}

		sAction.openPopup('ListViewPopup', `LBL_${relatedModule.toUpperCase()}`, false, {additionalData, module: relatedModule, disabledRecords, hiddenRecords, fromModule: module}, (recordsId) => {
			const params = {
				module: module,
				toModule: relatedModule,
				records: recordsId,
				recordId: recordId,
			};
			if (variantId) {
				params.variantId = variantId;
			}
			utils.post('createRelation', params).then((res) => {
				const updatedData = subpanels;
				updatedData[subpanelKey].data = res.data;

				if (!variantId) {
					// standard subpanel
					sAction.initSubpanels(updatedData, prefix);
				} else {
					// variants
					sAction.dsSet(`${prefix}/data`, res.data.data);
				}
			});
		});
	};

	/**
	 * Filters actions from disabled
	 *
	 * @returns {Array}
	 */
	const filterOptions = () => {
		let actionOptions = [
			{name: 'createNew', icon: 'addRecord', label: 'LBL_CREATE_NEW', onClick: createNew},
			{name: 'addExisting', icon: 'list', label: 'LBL_SELECT_EXISTING', onClick: addExisting},
		];

		const filteredActions = [];

		actionOptions.forEach((action) => {
			if (actions?.includes(action.name)) {
				filteredActions.push(action);
			}
		});

		return filteredActions;
	};

	const options = filterOptions();

	return (
		<div className="subpanel">
			<div className="subpanel__header">
				<div className="subpanel__headerBox">
					<div style={{backgroundColor: color}} className="subpanel__iconBox">
						<span className={`iconfas-${relatedModule}`} />
					</div>
					<div className="subpanel__title">{utils.translate(label)}</div>
				</div>
				<div className="subpanel__actionBox">
					<div className="subpanel__pagination">
						{offset >= 10 ? <div className="iconfas-angleLeft subpanel__button" onClick={() => setOffset(offset - 10)} /> : <div className="subpanel__button" />}
						<div className="subpanel__count">{(offset + 1) + ' - ' + (offset + 10)}</div>
						{data?.length > offset + 10 ? <div className="iconfas-angleRight subpanel__button" onClick={() => setOffset(offset + 10)} /> : <div className="subpanel__button" />}
					</div>
					{options.length > 0 ? 
						<Tooltip placement="top" title="LBL_ADD_RELATION">
							<div className="subpanel__box">
								<div onClick={(e) => handleAdd(e)} className="subpanel__button iconfas-addRecord">
									<Popover id={relatedModule} anchor={addAnchor} open={addOpen} options={options} />
								</div>
							</div>
						</Tooltip> : null}
					{customActions &&
					<div className="subpanel__box">
						<Tooltip placement="top" title="LBL_OTHER_ACTIONS">
							<div onClick={(e) => handleCustomActions(e)} className="subpanel__button iconfas-menu">
								<Popover id={relatedModule} anchor={addAnchor} open={customOpen} options={customActions} />
							</div>
						</Tooltip>
					</div>}
					<div className="subpanel__iconBox subpanel__statusControl">
						<span onClick={() => setOpen(!open)} className={`iconfas-angleUp ${!open ? 'subpanel__iconRotate' : ''}`} />
					</div>
				</div>
			</div>
			{data?.length > 0 && open ? <SubpanelTable
				offset={offset}
				relatedModule={relatedModule}
				columns={columns}
				data={data}
				module={module}
				recordId={recordId}
				subpanels={subpanels}
				prefix={prefix}
				subpanelKey={subpanelKey}
				variantId={variantId}
				rowActions={rowActions} /> : null}
		</div>
	);
}
