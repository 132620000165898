import React, { useState, useEffect } from 'react';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import utils from '../../utils';
import parseISO from 'date-fns/parseISO';
import isValid from 'date-fns/isValid';
import { enUS, cs, sk } from 'date-fns/locale';
import useAction from '../../store/actions';
import Tooltip from '@mui/material/Tooltip';
import {default as MuiTooltip} from '@mui/material/Tooltip';
import moment from 'moment';

/**
 * @param {Date} value
 * @param {Function} onChange
 * @param {String} fieldName
 * @param {String} label
 * @param {Boolean} disableFuture
 * @param {Boolean} disablePast
 * @param {String} error
 * @param {Boolean} required
 * @param {Date} defaultValue
 * @param {String} info
 *
 * @return {JSX}
 */
export default function DateField({
	value,
	onChange,
	fieldName,
	label,
	disableFuture,
	disablePast,
	error,
	required,
	defaultValue,
	info,
}) {
	const [localValue, setLocalValue] = useState(value || null);
	const sAction = useAction();
	const language = sAction.dsGet('user/language');
	const debugMode = localStorage.getItem('debugMode') ? true : false;

	useEffect(() => {
		setLocalValue(value);
	}, [value]);

	/**
	 * @param {Date} date
	 */
	const handleChange = (date) => {
		let formattedDate = moment(date, ['YYYY-MM-DD', 'DD.MM.YYYY'], true).format('YYYY-MM-DD');

		const momentDate = moment(formattedDate, 'YYYY-MM-DD', true);

		if (!momentDate.isValid() && formattedDate !== '0000-00-00') {
			formattedDate = 'Invalid date';
		}

		onChange({
			target: {
				name: fieldName,
				value: date != null ? formattedDate : '0000-00-00',
			},
		});
	};

	const localeMap = {
		en: enUS,
		cz: cs,
		sk: sk,
	};

	const locale = localeMap[language] || enUS;

	if (value === '0000-00-00') {
		value = null;
	}

	const clearLabel = utils.translate('LBL_CLEAR');

	return (
		<LocalizationProvider
		  	adapterLocale={locale}
		  	dateAdapter={AdapterDateFns}
			localeText={{
				clearButtonLabel: clearLabel,
		  	}}
		>
			<div className={`dateField ${error ? 'dateField__error' : 'dateField__outline'}`}>
				{label && (
					<label className="detailField__label">
						{label}
						{debugMode ? <span className="debugName"> {fieldName}</span> : null}
						{info ?
							<Tooltip placement="top" title={info}>
								<span className="fieldIcon iconfas-info"/>
							</Tooltip> : null}
						{required ? <span className="detailField__required">*</span> : null}
					</label>
				)}
				<DesktopDatePicker
					className="dateField__datePicker"
					value={isValid(parseISO(localValue)) ? parseISO(localValue) : localValue === '0000-00-00' ? null : parseISO(defaultValue)}
					format="dd.MM.yyyy"
					onChange={handleChange}
					name={fieldName}
					closeOnSelect={true}
					disableFuture={disableFuture}
					disablePast={disablePast}
					displayWeekNumber
					slotProps={{
						actionBar: {
						  	actions: ['clear'],
						},
					}}
				/>
				{error ? (
					<MuiTooltip arrow title={<p className="detailToolTip">{error}</p>} placement="top">
						<span className="dateField__tooltip iconfas-warning inputFieldBox__error-icon"></span>
					</MuiTooltip>
				) : null}
			</div>
		</LocalizationProvider>
	);
}