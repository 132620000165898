import React from 'react';
import ListView from '../ListView/ListView';
import { useSortable } from '@dnd-kit/sortable';
import Report from '../Reports/Report';
import Calendar from '../Calendar/Calendar';
import Overview from '../Overview/Overview';
import utils from '../../utils';

/**
 * @export
 * @param {Object} item
 * @param {Function} removeItem
 * @param {Number} index
 * @param {Object} config
 *
 * @return {JSX}
 */
export default function DashboardItem({item, removeItem, index, config, dashboard}) {
	const { attributes, listeners, setNodeRef, transform } = useSortable({ id: item.name });

	const itemStyle = {
		transform: transform
			? `translate3d(${transform.x}px, ${transform.y}px, 0)`
			: undefined,
	};

	/**
	 * @return {JSX}
	 */
	const renderItem = () => {
		if (item.type === 'list') {
			return (
				<ListView
					disableMultiple={true}
					disabledRecords={[]}
					additionalData={{}}
					hiddenRecords={[]}
					fromModule={''}
					defaultFilter={{}}
					predefinedFilter={{}}
					prefix={item.prefix}
					globalModule={item.module}
					onDashboard={true}
					config={config}
				/>
			);
		} else if (item.type === 'report') {
			return (
				<Report
					translate={config?.translate?.label}
					reportDataBase={item?.report}
				/>
			);
		} else if (item.type === 'calendar' || item.type === 'calendarList') {
			return (
				<Calendar
					prefix={item.prefix}
					config={config}
					calendarStore={item.calendar}
					displayCalendar={item.type === 'calendar' ? true : false}
					displayList={item.type === 'calendarList' ? true : false}
					onDashboard={true}
				/>
			);
		} else if (item.type === 'special') {
			if (item.name === 'overview') {
				return (
					<Overview />
				);
			}
		}
	};

	const translatedLabel = utils.translate(item.label);

	return (
		<div style={itemStyle} ref={setNodeRef} {...attributes} key={item.name} className="dashboard__item">
			<div className="dashboard__item-header">
				<span {...listeners} className="linesRow__icons-dnd iconfas-grip" />
				<span className="dashboard__item-label">{translatedLabel}</span>
				<span onClick={() => removeItem(index)} className="linesRow__icons-delete dashboard__item-delete iconfas-delete" />
			</div>
			<div className="dashboard__item-content">
				{renderItem()}
			</div>
		</div>
	);
}
