import React, { useEffect, useState, lazy, Suspense } from 'react';
import utils from '../../../../utils';
import useAction from '../../../../store/actions';
import { Skeleton } from '@mui/material';

const Subpanel = lazy(() => import('./Subpanel'));

/**
 * @export
 * @param {String} module
 * @param {String} recordId
 * @param {String} prefix
 * @param {Object} viewData
 *
 * @return {*}
 */
export default function Subpanels({
	module,
	recordId,
	prefix,
	viewData,
}) {
	const [load, setLoad] = useState(false);
	const sAction = useAction();
	const subpanels = viewData.detail.subpanels;

	useEffect(() => {
		setLoad(true);
		utils.post('getDetailSubpanels', { module, recordId })
			.then((res) => {
				setLoad(false);
				sAction.initSubpanels(res.data.subpanels, prefix);
			})
			.catch((error) => {
				console.error(error);
				setLoad(false);
			});
	}, []);

	/**
	 * @return {JSX}
	 */
	const renderSubpanels = () => {
		return Object.keys(subpanels).map((key, i) => {
			const subpanel = subpanels[key];
			if (load) {
				return <Skeleton key={key} className="subpanels__load" variant="rectangular" height={40} />;
			}
			return (
				<Suspense fallback={<Skeleton className="subpanels__load" variant="rectangular" height={40} />} key={key}>
					<Subpanel
						data={subpanel.data}
						relatedModule={subpanel.relatedModule}
						label={subpanel.label}
						columns={subpanel.columns}
						color={window.colorPalette[i % window.colorPalette.length]}
						recordId={recordId}
						module={module}
						subpanelKey={key}
						prefix={prefix}
						subpanels={subpanels}
						actions={subpanel?.actions}
						rowActions={subpanel?.rowActions}
					/>
				</Suspense>
			);
		});
	};

	return (
		<div className="subpanels">
			{renderSubpanels()}
		</div>
	);
}
