import React, {useState, useEffect, useMemo} from 'react';
import useAction from '../../store/actions';
import utils from '../../utils';
import ActionButtons from '../ActionButtons/ActionButtons';
import ButtonBasic from '../Common/Buttons/ButtonBasic';
import Popover from '../Common/Popover/Popover';
import DashboardItem from './DashboardItem';
import { DndContext } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import storeStates from '../../store/states/index';
import toast from 'react-hot-toast';
import { data } from 'jquery';

/**
 * @export
 *
 * @return {JSX}
 */
export default function Dashboard({dashboard, config, userData}) {
	const sAction = useAction();
	const [localDashboard, setLocalDashboard] = useState(dashboard);
	const [moduleAnchor, setModuleAnchor] = useState(null);
	const [modulesOpen, setModulesOpen] = useState(false);
	const [originalReportOptions, setOriginalReportOptions] = useState([]);
	const [reportOptions, setReportOptions] = useState([]);
	const [reportAnchor, setReportAnchor] = useState(null);
	const [reportOpen, setReportOpen] = useState(false);
	const [standardModuleListOptions, setStandardModuleListOptions] = useState([]);
	const [specialAnchor, setSpecialAnchor] = useState(null);
	const [specialOpen, setSpecialOpen] = useState(false);
	const [specialOptions, setSpecialOptions] = useState([]);
	const [dashboardSettings, setDashboardSettings] = useState(userData?.dashboardSettings);
	const enums = config.translate.enum;

	const errorLoadLabel = utils.translate('LBL_ERROR_LOADING_DASHBOARD');

	useEffect(() => {
		setDashboardSettings(userData?.dashboardSettings);
	}, [userData?.dashboardSettings]);

	/**
     * @param {Object} params
     */
	const addItem = (params) => {
		let itemToSet = {};
		if (params.type === 'list') {
			itemToSet = {name: params.name, type: params?.type, module: params?.module, prefix: `dashboard/${localDashboard?.length}`, list: {}, label: params.label};
			setLocalDashboard([...localDashboard, itemToSet]);
			sAction.dsSet(`dashboard/${localDashboard?.length}`, itemToSet);
		} else if (params.type === 'report') {
			itemToSet = {name: params.name, type: params?.type, prefix: `dashboard/${localDashboard?.length}`, report: params.report, label: params.label};
			setLocalDashboard([...localDashboard, itemToSet]);
			sAction.dsSet(`dashboard/${localDashboard?.length}`, itemToSet);
		} else if (params.type === 'calendar' || params.type === 'calendarList') {
			itemToSet = {name: params.type, type: params.type, calendar: storeStates.view.calendar, prefix: `dashboard/${localDashboard?.length}`, label: params.label};
			setLocalDashboard([...localDashboard, itemToSet]);
			sAction.dsSet(`dashboard/${localDashboard?.length}`, itemToSet);
		} else if (params.type === 'special') {
			itemToSet = {name: params.name, type: params?.type, prefix: `dashboard/${localDashboard?.length}`, label: params.label};
			setLocalDashboard([...localDashboard, itemToSet]);
			sAction.dsSet(`dashboard/${localDashboard?.length}`, itemToSet);
		}

		saveDashboard([...localDashboard, itemToSet]);
	};

	/***/
	const loadSpecialModules = () => {
		const optionsStandardToSet = [];
		if (enums?.specialModuleList) {
			Object.keys(enums?.specialModuleList)?.forEach((key) => {
				const item = enums?.specialModuleList[key];
				if (key === 'calendar') {
					optionsStandardToSet.push({name: 'calendar', type:'calendar', icon: 'calendar', label: 'LBL_CALENDAR', onClick: addItem});
				} else if (key === 'calendarList') {
					optionsStandardToSet.push({name: 'calendarList', type:'calendarList', icon: 'list', label: 'LBL_CALENDAR_LIST', onClick: addItem});
				} else if (key === 'overview') {
					optionsStandardToSet.push({name: 'overview', type:'special', icon: 'report', label: 'LBL_OVERVIEW', onClick: addItem});
				}
			});
			setSpecialOptions(optionsStandardToSet);
		}
	};

	// Here special modules are set for select
	useEffect(() => {
		loadSpecialModules();
	}, [enums?.specialModuleList, enums?.specialModuleList, localDashboard?.length]);

	// Here standard modules are set for select
	useEffect(() => {
		const optionsStandardToSet = [];
		if (enums?.standardModuleList) {
			Object.keys(enums?.standardModuleList)?.forEach((key) => {
				const module = enums?.standardModuleList[key];
				optionsStandardToSet.push({name: key, module: key, type:'list', icon: key, label: module, onClick: addItem, disabled: isModuleDisplayed(key)});
			});

			setStandardModuleListOptions(optionsStandardToSet);
		}
	}, [enums?.standardModuleList, enums?.specialModuleList, localDashboard?.length]);

	useEffect(() => {
		const reportsToSet = [];
		originalReportOptions.forEach((report) => {
			let displayed = false;
			localDashboard.forEach((item) => {
				if (item.name === report.name) {
					displayed = true;
					return;
				}
			});
			reportsToSet.push({...report, onClick: addItem, disabled: displayed});
		});
		setReportOptions(reportsToSet);

		const specialToSet = [];
		specialOptions.forEach((special) => {
			let displayed = false;
			localDashboard.forEach((item) => {
				if (item.name === special.name) {
					displayed = true;
					return;
				}
			});
			specialToSet.push({...special, onClick: addItem, disabled: displayed});
		});
		setSpecialOptions(specialToSet);
	}, [localDashboard?.length]);

	// Here reports and specials are set for select
	useEffect(() => {
		loadSpecialModules();

		utils
			.post('getReportList', {module: 'report'})
			.then((res) => {
				if (res?.data) {
					const reportsToSet = [];
					Object.keys(res?.data)?.forEach((key) => {
						const reportCategory = res?.data[key];
						reportCategory?.reports?.forEach((report) => {
							reportsToSet.push({name: report.name, type:'report', icon: report.icon, label: report.name, report: report, onClick: addItem});
						});
					});

					setOriginalReportOptions(reportsToSet);
					setReportOptions(reportsToSet);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	useEffect(() => {
		setLocalDashboard(dashboard);
	}, [dashboard]);

	/***/
	const saveDashboard = (newDashboard) => {
		const dashboardToSave = [];

		newDashboard.forEach((item, i) => {
			const itemToPush = {...item};
			if (item.type !== 'report') {
				itemToPush[item.type] = {};
			}
			dashboardToSave.push({...itemToPush});
		});

		utils.post('saveDashboard', {module: 'dashboard', dashboard: dashboardToSave})
			.then((res) => {})
			.catch((error) => {
				console.error(error);
			});
	};

	/**
	 * @param {String} name
	 * @return {Boolean}
	 */
	const isModuleDisplayed = (name) => {
		let isDisplayed = false;
		localDashboard.forEach((item) => {
			if (item.name === name) {
				isDisplayed = true;
			}
		});

		return isDisplayed;
	};

	/**
     * @param {Event} e
	 * @param {String} type
     */
	const openPopover = (e, type) => {
		if (type === 'list') {
			setModuleAnchor(e.currentTarget);
			setModulesOpen(!modulesOpen);
		} else if (type === 'report') {
			setReportAnchor(e.currentTarget);
			setReportOpen(!reportOpen);
		} else if (type === 'special') {
			setSpecialAnchor(e.currentTarget);
			setSpecialOpen(!specialOpen);
		}
	};

	/**
	 * @param {Number} index
	 *
	 * @return {Void}
	 */
	const removeItem = (index) => {
		const newDashboard = [];
		const filteredDashboard = localDashboard.filter((_, i) => i !== index);
		filteredDashboard.forEach((item, i) => {
			newDashboard.push({...item, prefix: `dashboard/${i}`});
		});

		setLocalDashboard(newDashboard);
		sAction.dsSet('dashboard', newDashboard);
		saveDashboard(newDashboard);
	};

	/***/
	const renderItems = useMemo(() => {
		return localDashboard?.map((item, i) => {
			if (!item) {
				return null;
			}
			return (
				<DashboardItem
					removeItem={removeItem}
					key={item.name}
					item={item}
					index={i}
					config={config}
				/>
			);
		});
	}, [localDashboard, localDashboard.length, removeItem]);

	/**
	 * @param {Event} event
	 */
	const handleDragEnd = (event) => {
		const { over, active } = event;
		if (over) {
			const fromIndex = active.data.current.sortable.index;
			const toIndex = over.data.current.sortable.index;
			const newArray = utils.moveItemInArray(dashboard, fromIndex, toIndex);
			const arrayToSet = [];
			newArray.forEach((item, i) => {
				arrayToSet.push({...item, prefix: `dashboard/${i}`});
			});
			setLocalDashboard(arrayToSet);
			sAction.dsSet('dashboard', arrayToSet);
			saveDashboard(arrayToSet);
		}
	};

	const settingsRowsSettings = dashboardSettings?.numberOfRows == 1 ?
		`repeat(${Math.ceil(localDashboard?.length / Number(dashboardSettings.numberOfColumns))}, calc(100% - 1rem))` :
		`repeat(${Math.ceil(localDashboard?.length / Number(dashboardSettings.numberOfColumns))}, calc(50% - 1rem))`;

	/**
	 * @return {Void}
	 */
	const openDashboardSettings = () => {
		sAction.openPopup('DashboardSettings', 'LBL_DASHBOARD_SETTINGS', false, dashboardSettings, (newSettings) => {
			setDashboardSettings(newSettings);
			sAction.dsSet('user/dashboardSettings', newSettings);
		});
	};

	/***/
	const getColumnsLayout = (columnsCount) => {
		let style = '';

		for (let i = 1; i <= Number(columnsCount); i++) {
			style = `${style} 1fr`;
		}

		return style;
	};

	return (
		<div className="home dashboard">
			<ActionButtons className="dashboard__buttons">
				<ButtonBasic
					icon="settings"
					onClick={() => openDashboardSettings()}
					label={'LBL_SETTINGS'}
					className="reverseColorButton"
				/>
				{standardModuleListOptions.length > 0 && <ButtonBasic
					icon="box"
					onClick={(e) => openPopover(e, 'list')}
					label={'LBL_STANDARD_MODULES'}
					onClickSendProps={true}
				>
					<Popover
						id={'addStandardModuleDashboard'}
						anchor={moduleAnchor}
						open={modulesOpen}
						options={standardModuleListOptions}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'left',
						}}
					/>
				</ButtonBasic>}
				{originalReportOptions?.length > 0 && <ButtonBasic
					icon="report"
					onClick={(e) => openPopover(e, 'report')}
					label={'LBL_REPORT'}
					onClickSendProps={true}
				>
					<Popover
						id={'addReportDashboard'}
						anchor={reportAnchor}
						open={reportOpen}
						options={reportOptions}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'left',
						}}
					/>
				</ButtonBasic>}
				{specialOptions.length > 0 && <ButtonBasic
					icon="star"
					onClick={(e) => openPopover(e, 'special')}
					label={'LBL_REP_CAT_OTHERS'}
					onClickSendProps={true}
				>
					<Popover
						id={'addSpecialDashboard'}
						anchor={specialAnchor}
						open={specialOpen}
						options={specialOptions}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'left',
						}}
					/>
				</ButtonBasic>}
			</ActionButtons>
			<div style={{gridTemplateRows: settingsRowsSettings, gridTemplateColumns: getColumnsLayout(dashboardSettings.numberOfColumns)}} className="dashboard__content">
				<DndContext onDragEnd={handleDragEnd}>
					<SortableContext items={localDashboard.map(item => item.name)}>
						{renderItems}
					</SortableContext>
				</DndContext>
			</div>
		</div>
	);
}
