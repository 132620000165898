import React, {useState, useEffect} from 'react';
import Subpanel from '../Subpanels/Subpanel';
import { Tab, Tabs } from '@mui/material';
import utils from '../../../../utils';
import useAction from '../../../../store/actions';
import Tooltip from '../../../Common/Tooltip/Tooltip';
import { Skeleton } from '@mui/material';

/**
 * @export
 * @param {Number | String} recordId
 * @param {String} prefix
 * @param {Object} viewData
 *
 * @return {*}
 */
export default function ProductVariant({recordId, prefix, viewData}) {
	const [variantId, setVariantId] = useState(null);
	const [open, setOpen] = useState(true);
	const [load, setLoad] = useState(false);
	const sAction = useAction();

	const variants = viewData?.detail?.productVariant?.variants;
	const colors = window.colorPalette;

	const findSelectedVariant = (element) => element.id == variantId;
	const variantName =  variants?.[variants?.findIndex(findSelectedVariant)]?.name;

	useEffect(() => {
		setVariantId(null);
		setLoad(true);
		utils.post('getProductVariants', {recordId, module: viewData.module})
			.then((res) => {
				if (res.data.length > 0) {
					sAction.dsSet(`${prefix}/detail/productVariant/variants`, res.data);
				}
				setLoad(false);
			}).catch((error) => {
				console.error(error);
				setLoad(false);
			});

		if (variants?.length > 0) {
			setVariantId(variants?.[0]?.id);
		}
	}, [recordId]);

	useEffect(() => {
		if (!variantId) {
			setVariantId(variants?.[0]?.id);
		}
	}, [variants]);

	useEffect(() => {
		if (variantId) {
			utils.post('getVariantRelatedProducts', {recordId, variantId, module: viewData.module})
				.then((res) => {
					sAction.dsSet(`${prefix}/detail/productVariant/data`, res.data?.data);
					sAction.dsSet(`${prefix}/detail/productVariant/columns`, res.data?.columns);
					sAction.dsSet(`${prefix}/detail/productVariant/rowActions`, res.data?.rowActions);
					sAction.dsSet(`${prefix}/detail/productVariant/actions`, res.data?.actions);
				}).catch((error) => {
					console.error(error);
				});
		}
	}, [variantId]);

	/**
	 * Function for deleting variant
	 *
	 */
	const deleteVariant = () => {
		sAction.confirmPopup('LBL_CONFIRM_DELETE_VARIANT', () => {
			const tempVariants = variants;
			const variantIndex = variants?.findIndex(findSelectedVariant);
			if (variantIndex > -1) {
				tempVariants.splice(variantIndex, 1);
			}
			sAction.dsSet(`${prefix}/detail/productVariant/variants`, tempVariants);
			setVariantId(tempVariants[0]?.id);
			utils.post('deleteProductVariant', {recordId, variantId, module: viewData.module})
				.then((res) => {
					sAction.closePopup();
				}).catch((error) => {
					console.error(error);
				});
		});
	};

	/**
	 * Function for renaming variant
	 *
	 */
	const renameVariant = () => {
		sAction.openPopup('SimpleInputPopup', 'LBL_NAME_OF_CONSTRUCTION_VARIANT', true, {inputLabel: 'LBL_NAME', defaultValue: variantName}, (inputValue) => {
			utils.post('renameProductVariant', {variantId, name: inputValue, module: viewData.module})
				.then((res) => {
					if (res.data > 0) {
						const tempVariants = variants;
						tempVariants[variants?.findIndex(findSelectedVariant)].name = inputValue;
						sAction.dsSet(`${prefix}/detail/productVariant/variants`, tempVariants);
						sAction.closePopup();
					}
				}).catch((error) => {
					console.error(error);
				});
		});
	};

	const subpanelActions = [
		{icon: 'edit', label: 'LBL_RENAME_VARIANT', onClick: renameVariant},
		{icon: 'delete', label: 'LBL_DELETE_VARIANT', onClick: deleteVariant},
	];

	/**
	 * Function for creating variant
	 *
	 */
	const createProductVariant = () => {
		sAction.openPopup('SimpleInputPopup', 'LBL_NAME_OF_CONSTRUCTION_VARIANT', true, {inputLabel: 'LBL_NAME'}, (inputValue) => {
			utils
				.post('createProductVariant', {module: viewData.module, recordId: recordId, name: inputValue})
				.then((res) => {
					if (res.data > 0) {
						const newVariants = variants || [];
						newVariants.push({
							id: res.data,
							name: inputValue,
						});
						sAction.dsSet(`${prefix}/detail/productVariant/variants`, newVariants);
						sAction.closePopup();
					}
			 	})
			 	.catch((error) => {
			 		console.error(error);
				});
		});
	};

	const variantsLabel = utils.translate('LBL_VARIANTS');

	return (
		<div className="productVariant">
			{load ? <Skeleton variant="rectangular" height={40} /> : <div className="productVariant__box">
				<div className="subpanel__header">
					<div className="subpanel__headerBox">
						<div style={{backgroundColor: '#6F7AAD'}} className="subpanel__iconBox">
							<span className="iconfas-variants" />
						</div>
						<div className="subpanel__title">{variantsLabel}</div>
					</div>
					<div className="subpanel__actionBox">
						<div className="subpanel__box">
							<Tooltip placement="top" title="LBL_ADD_VARIANT">
								<div className="subpanel__button iconfas-addRecord" onClick={() => createProductVariant()} />
							</Tooltip>
						</div>
					</div>
					<div className="subpanel__iconBox subpanel__statusControl">
						<span onClick={() => setOpen(!open)} className={`iconfas-angleUp ${!open ? 'subpanel__iconRotate' : ''}`} />
					</div>
				</div>
				{!open || !variants ? null :
					<Tabs TabIndicatorProps={{
						style: {
							backgroundColor: colors[variants?.findIndex(findSelectedVariant) % colors?.length]
						}
					}}
					className="productVariant__tabs"
					value={variantId || variants?.[0]?.id}
					onChange={(_e, variantId) => setVariantId(variantId)}>
						{
							variants?.map((variant, index) => {
								return (
									<Tab
										key={variant?.id}
										label={variant?.name}
										value={variant?.id}
										icon={variants?.length !== index &&
										<div
											style={{backgroundColor: colors[variants?.findIndex(findSelectedVariant) % colors?.length]}}
											className="productVariant__tabDivider"
										/>
										}
										iconPosition="end"
									/>
								);
							})
						}
					</Tabs>}
			</div>}
			<div>
				{!variants || variants?.length === 0 || !open ? null :
					<Subpanel
						color={colors[variants?.findIndex(findSelectedVariant) % colors?.length]}
						columns={viewData?.detail?.productVariant?.columns}
						data={viewData?.detail?.productVariant?.data}
						label={variantName}
						module={'productVariant'}
						prefix={`${prefix}/detail/productVariant`}
						relatedModule={'product'}
						recordId={recordId}
						customActions={subpanelActions}
						subpanelKey="data"
						subpanels={viewData?.detail?.productVariant}
						variantId={variantId}
						rowActions={viewData?.detail?.productVariant?.rowActions}
						actions={viewData?.detail?.productVariant?.actions}
					/>}
			</div>
		</div>
	);
}
