import React, {useMemo, useState, useEffect} from 'react';
import Input from '../FormElements/Input';
import utils from '../../utils';
import Phone from '../FormElements/Phone';
import Select from '../FormElements/Select';
import Image from '../FormElements/Image';
import useAction from '../../store/actions';
import ActionButtons from '../ActionButtons/ActionButtons';
import ButtonBasic from '../Common/Buttons/ButtonBasic';
import Date from '../FormElements/DateField';
import toast from 'react-hot-toast';
import Switch from '../FormElements/Switch';

/**
 * @export
 * @param {Object} userData
 * @return {JSX}
 */
export default function userSettings({userData}) {
	const [state, setState] = useState({});
	const sAction = useAction();
	const [password, setPassword] = useState('');
	const [password2, setPassword2] = useState('');
	const [error, setError] = useState({});
	const [saveDisabled, setSaveDisabled] = useState(true);

	const handleImageDelete = () => {
		utils.post('deleteFile', {module: 'myProfile', recordId: userData.id}).then((res) => {
			setState({...state, photo: ''});
			sAction.dsSet('user/photo', '');
		});
	};

	const handleImageChange = (e) => {
		e.preventDefault();
		sAction.load();

		const formData = {};
		formData.module = 'myProfile';
		formData.file = e.target.files[0];
		formData.recordId = userData.id;

		utils.post('uploadFile', formData).then((res) => {
			setState({...state, photo: res.data?.value});
			sAction.dsSet('user/photo', res.data?.value);
			sAction.unload();
		});
	};

	const handleChange = (e) => {
		if (typeof e.name !== 'undefined' && e.name === 'photo') {
			setState({...state, [e.name] : e.value});
			sAction.dsSet(`user/${e.name}`, e.value);
		} else {
			const newState = {...state, [e.target.name]: e.target.value};
			setState(newState);
			sAction.dsSet(`user/changes/${e.target.name}`, e.target.value);

			if (e.target.name === 'password') {
				setPassword(e.target.value);
			}

			if (e.target.name === 'password2') {
				setPassword2(e.target.value);
			}

			let errorMessage = null;
			error[e.target.name] = null;

			if (e.target.required && e.target.value === '') {
				errorMessage = 'LBL_REQUIRED_FIELD';
			}

			if (e.target.name === 'email') {
				if (!utils.validateEmail(e.target.value)) {
					errorMessage = 'LBL_INVALID_EMAIL';
				}
			}

			if (e.target.name === 'password' && e.target.value != '') {
				if (e.target.value.length < 6) {
					errorMessage = 'LBL_INVALID_PASSWORD_MIN_LENGTH';
				}
			}

			if (e.target.name === 'password2' && e.target.value != '') {
				if (password != e.target.value) {
					errorMessage = 'LBL_INVALID_PASSWORD_NOT_SAME';
				}
			}

			error[e.target.name] = errorMessage;
			setError(error);

			let isError = false;
			Object.keys(error).map((key) => {
				if (error[key]) {
					isError = true;
				}
			});

			setSaveDisabled(isError);
		}
	};

	const handleKeyPress = (e) => {
		if(e.key === 'Enter' && !saveDisabled){
			saveMyProfile();
		}
	};

	const labelSave = utils.translate('LBL_SAVE_SUCCESSFUL');
	const labelError = utils.translate('LBL_ERROR');
	const labelSaving = utils.translate('LBL_SAVING');

	const saveMyProfile = async () => {
		Object.keys(userData.changes).map((key) => {
			sAction.dsSet(`user/${key}`, userData.changes[key]);
		});

		const resultPromise = utils.post('saveUserSettings', userData.changes);

		toast.promise(resultPromise,
			{
				loading: labelSaving,
				success: labelSave,
				error: labelError,
			}
		);

		try {
			const res = await resultPromise;

			if (res.data) {
				if (userData.changes.language) {
					sAction.changeLanguage(res.translates);
				}
				setPassword('');
				setPassword2('');
				sAction.dsSet('user/changes', {});
				setSaveDisabled(true);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const initialState = useMemo(
		() => setState(userData),
		[userData]
	);

	const selectOptions = utils.getEnum('languages');
	const stateOptions = utils.getEnum('states');
	return (
		<>
			<div className="actionBox">
				<ActionButtons>
					<ButtonBasic id={'saveButton'} label={'LBL_SAVE'} key={'save'} icon={'save'} style={'green'} disabled={saveDisabled} onClick={() => saveMyProfile()} />
				</ActionButtons>
			</div>
			<div className="userSettings__box">
				<h3 className="userSettings__header">{utils.translate('LBL_USER_SETTINGS')}</h3>
				<div className="userSettings" onKeyDown={handleKeyPress}>
					<div className="userSettings__fields">
						<div className="inputRow">
							<Input
								onChange={(e) => handleChange(e)}
								fieldLength={32}
								placeholder={''}
								readonly={true}
								value={state.username}
								required={true}
								label={utils.translate('LBL_USERNAME')}
								fieldName="username"
							/>
							<Input
								error={utils.translate(error['email'])}
								onChange={(e) => handleChange(e)}
								fieldLength={100}
								placeholder={''}
								readonly={false}
								value={state.email}
								required={true}
								label={utils.translate('LBL_EMAIL')}
								fieldName="email"
							/>
						</div>
						<div className="inputRow">
							<Input
								error={utils.translate(error['firstName'])}
								onChange={(e) => handleChange(e)}
								fieldLength={30}
								placeholder={''}
								readonly={false}
								value={state.firstName}
								required={true}
								label={utils.translate('LBL_FIRST_NAME')}
								fieldName="firstName"
							/>
							<Input
								error={utils.translate(error['lastName'])}
								onChange={(e) => handleChange(e)}
								fieldLength={30}
								placeholder={''}
								readonly={false}
								value={state.lastName}
								required={true}
								label={utils.translate('LBL_LAST_NAME')}
								fieldName="lastName"
							/>
						</div>
						<div className="inputRow">
							<Input
								onChange={(e) => handleChange(e)}
								fieldLength={30}
								placeholder={''}
								readonly={false}
								value={state.nickname}
								required={false}
								label={utils.translate('LBL_NICKNAME')}
								fieldName="nickname"
							/>
							<Phone
								onChange={(e) => handleChange(e)}
								fieldLength={9}
								placeholder={utils.translate('LBL_PHONE')}
								readonly={false}
								value={state.phone}
								required={false}
								label={utils.translate('LBL_PHONE')}
								fieldName="phone"
							/>
						</div>
						<div className="inputRow">
							<Date
								onChange={(e) => handleChange(e)}
								readonly={false}
								value={state.dob}
								label={utils.translate('LBL_DOB')}
								fieldName="dob"
							/>
							<Select
								onChange={(e) => handleChange(e)}
								placeholder={''}
								readonly={false}
								value={state.language}
								options={selectOptions}
								required={false}
								label={utils.translate('LBL_LANGUAGE')}
								fieldName="language"
							/>
						</div>
						<div className="inputRow">
							<Input
								error={utils.translate(error['password'])}
								type="password"
								onChange={(e) => handleChange(e)}
								fieldLength={32}
								placeholder={''}
								readonly={false}
								value={password}
								required={false}
								label={utils.translate('LBL_PASSWORD')}
								fieldName="password"
							/>
							<Input
								error={utils.translate(error['password2'])}
								type="password"
								onChange={(e) => handleChange(e)}
								fieldLength={32}
								placeholder={''}
								readonly={false}
								value={password2}
								required={false}
								label={utils.translate('LBL_PASSWORD2')}
								fieldName="password2"
							/>
						</div>
						<div className="emptyLine emptyLine__myProfile">
							{utils.translate('LBL_ADDRESS')}
						</div>
						<div className="inputRow">
							<Input
								onChange={(e) => handleChange(e)}
								fieldLength={150}
								placeholder={''}
								value={state.street}
								required={false}
								label={utils.translate('LBL_STREET')}
								fieldName="street"
							/>
						</div>
						<div className="inputRow">
							<Input
								onChange={(e) => handleChange(e)}
								fieldLength={32}
								placeholder={''}
								value={state.descriptiveNumber}
								required={false}
								label={utils.translate('LBL_DESCRIPTIVE_NUMBER')}
								fieldName="descriptiveNumber"
							/>
						</div>
						<div className="inputRow">
							<Input
								onChange={(e) => handleChange(e)}
								fieldLength={32}
								placeholder={''}
								value={state.city}
								required={false}
								label={utils.translate('LBL_CITY')}
								fieldName="city"
							/>
						</div>
						<div className="inputRow">
							<Input
								onChange={(e) => handleChange(e)}
								fieldLength={32}
								placeholder={''}
								value={state.zip}
								required={false}
								label={utils.translate('LBL_ZIP')}
								fieldName="zip"
							/>
						</div>
						<div className="inputRow">
							<Select
								onChange={(e) => handleChange(e)}
								value={state.state}
								required={false}
								label={utils.translate('LBL_STATE')}
								fieldName="state"
								options={stateOptions}
							/>
						</div>
						<div className="emptyLine emptyLine__myProfile">
							{utils.translate('LBL_OTHER_SETTINGS')}
						</div>
						<div className="inputRow__empty"/>
					</div>
					<div className="userSettings__photoBox">
						<Image
							onChange={(e) => handleImageChange(e)}
							userData={userData}
							allowDelete={true}
							allowUpload={true}
							readonly={false}
							required={false}
							label={utils.translate('LBL_PROFILE_PHOTO')}
							fieldName={'photo'}
							module={'myProfile'}
							recordId={userData.id}
							value={state.photo}
							handleDelete={handleImageDelete}
							askDelete={true}
						/>
					</div>
				</div>
			</div>
		</>
	);
}